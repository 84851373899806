import "./Card.css";

const Card = ({ providedData }: any) => {
	console.log(providedData);
	const month: any = {
		1: "January",
		2: "Feburary",
		3: "March",
		4: "April",
		5: "May",
		6: "June",
		7: "July",
		8: "August",
		9: "september",
		10: "October",
		11: "November",
		12: "December",
	};
	return (
		<>
			<div className="twoCard">
				<div>
					<p>
						Orders :{" "}
						{providedData.date !== undefined
							? providedData.date +
							  "/" +
							  providedData.month +
							  "/" +
							  providedData.year
							: ""}
					</p>
					<p>Successful Orders : {providedData?.successfulToday}</p>
					<p>Failed Orders : {providedData?.failedToday}</p>
					<p>Cancelled Orders : {providedData?.cancelledToday}</p>
				</div>
				<div>
					<p> Orders : {month[providedData.month]} </p>
					<p>Successful Orders : {providedData?.successfulThisMonth}</p>
					<p>Failed Orders : {providedData?.failedThisMonth}</p>
					<p>Cancelled Orders : {providedData?.cancelledThisMonth}</p>
				</div>
			</div>
		</>
	);
};

export default Card;
