import React from "react";
import "./MainLayout.css";
import { db } from "../FirebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import Table from "../Components/table/Table";
import Card from "../Components/card/Card";

const MainLayout = () => {
	const [providedData, setProvidedData] = React.useState<any>({ any: [] });
	const [isLoading, setIsLoading] = React.useState(false);
	let monthlyOrdersData: any;
	React.useEffect(() => {
		const firebaseImplimentation = async () => {
			const docrefId = await doc(db, "countOrders", "live");
			console.log(docrefId, "dref");
			if (docrefId) {
				setIsLoading(true);
			}
			const unsub = await onSnapshot(docrefId, (snapshot) => {
				setProvidedData(snapshot?.data());
				console.log(snapshot?.data(), "ssss");
			});
			console.log(providedData, unsub, "provider Data");

			return () => {
				unsub();
			};
		};
		firebaseImplimentation();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const coloumn = [
		{ heading: "Date", value: `date.seconds` },
		{ heading: "Successful Orders", value: "successfulOrders" },
		{ heading: "Failed orders", value: "failedOrders" },
		{ heading: "Cancelled Orders", value: "cancelledOrders" },
	];
	monthlyOrdersData = providedData.monthlyOrdersData;

	if (
		monthlyOrdersData &&
		monthlyOrdersData.length !== 0 &&
		monthlyOrdersData[0].date.seconds
	) {
		monthlyOrdersData.sort((a: any, b: any) => b.date.seconds - a.date.seconds);
	}

	return (
		<div className="MainLayout">
			<header className="MainLayout-Body">
				{isLoading === true ? <Card providedData={providedData} /> : ""}
				<div className="Total-order ">
					Successful Orders Till date :{" "}
					{isLoading === true ? providedData.successfulOrdersTillDate : ""}
				</div>
				{isLoading === true && monthlyOrdersData?.length !== 0 ? (
					<Table data={monthlyOrdersData} coloumn={coloumn} />
				) : (
					""
				)}
			</header>
		</div>
	);
};

export default MainLayout;
