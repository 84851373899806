import MainLayout from "./Layout/MainLayout";

function App() {
	return (
		<div className="App">
			<header className="">
				<MainLayout />
			</header>
		</div>
	);
}

export default App;
