import "./table.css";

const Table = ({ data, coloumn }: any) => {
	console.log(data);
	if (data !== undefined) {
		return (
			<table>
				<thead>
					{coloumn.map((item: any, index: any) => (
						<TableHeader item={item} />
					))}
				</thead>
				<tbody>
					{data.map((item: any) => (
						<TableBody item={item} coloumn={coloumn} />
					))}
				</tbody>
			</table>
		);
	} else return <div></div>;
};

const TableHeader = ({ item }: any) => <th>{item.heading}</th>;
const TableBody = ({ item, coloumn }: any) => (
	<tr>
		{coloumn.map((columnItem: any) => {
			if (columnItem.value.includes(".")) {
				const itemSplit = columnItem.value.split("."); //['date', 'seconds']
				if (itemSplit[0] === "date") {
					const newDateInSeconds = item[itemSplit[0]][itemSplit[1]];
					const newDate = new Date(newDateInSeconds * 1000)
						.toString()
						.split(" ");
					return <td>{newDate[2] + " " + newDate[1] + " " + newDate[3]}</td>;
				} else {
					return <td>{item[itemSplit[0]][itemSplit[1]]}</td>;
				}
			}

			return <td>{item[`${columnItem.value}`]}</td>;
		})}
	</tr>
	// <tr>
	// 	{coloumn.map((colItem: any) => (
	// 		// colItem.heading !== "Date" ? (
	// 		// 	<td>
	// 		// 		{
	// 		// 			(console.log(
	// 		// 				item,
	// 		// 				item[`${colItem.value}`],
	// 		// 				colItem.value,
	// 		// 				"body"
	// 		// 			),
	// 		// 			item[`${colItem.value}`])
	// 		// 		}
	// 		// 	</td>
	// 		// ) : (
	// 		// 	console.log(new Date(item[`${colItem.value}`]))
	// 		// )
	// 		<td>{item[`${colItem.value}`]}</td>
	// 	))}
	// </tr>
);

export default Table;
